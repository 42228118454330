@import "styles/mixins";

.mintContainer {
  background-color: #1e1e1e;
  color: $mint-pink;
  padding-bottom: 10px;

  @include media(mdx) {
    min-height: 100vh;
  }

  section {
    padding: 0 10px;
    position: relative;
    h1 {
      font-family: bd-geminis, sans-serif;
      font-size: 94px;
      font-weight: 100;
      padding-top: 40px;
      position: relative;
      text-transform: uppercase;
      z-index: 1;
      @include media(mdx) {
        font-size: 240px;
        padding: 0;
      }
    }
  }

  hr {
    margin: 20px 10px;
    border-color: $mint-pink;
  }
}

.mintPacks {
  overflow-x: hidden;

  h2 {
    font-size: 20px;
    line-height: 20px;
    max-width: 230px;
    span {
      color: #fff;
    }
    @include media(mdx) {
      font-size: 32px;
      line-height: 32px;
      max-width: initial;
    }
  }

  h1 {
    padding-top: 10px !important;
    @include media(mdx) {
      max-width: 1300px;
      padding-top: 0 !important;
    }
  }

  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 26.7px;
    max-width: 380px;
    position: relative;
    z-index: 1;
    @include media(mdx) {
      max-width: 600px;
    }
  }

  button, a {
    background-color: $mint-pink;
    line-height: 14.4px;
    border: none;
    width: 100%;
    @include media(mdx) {
      display: inline;
      width: auto;
    }
  }

  a {
    text-align: center;
  }

  button:disabled {
    background-color: rgba($color: $mint-pink, $alpha: 0.3);
    color: #0c0b0b;
  }

  .mintHeaderDesktop {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 450px;
    @include media(mdx) {
      display: block;
    }
  }

  .mintHeaderMobile {
    height: 380px;
    position: absolute;
    left: 200px;
    top: 0;
    transform: rotate(-15deg);
    z-index: 0;
    @include media(mdx) {
      display: none;
    }
  }

  .mintLabel {
    font-size: 12px;
    font-weight: 900;
    margin-top: 20px;
    text-transform: uppercase;
  }

  .packsToMint {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 20px;
    @include media(mdx) {
      display: block;
      padding-bottom: 0;
    }
  }

  .mintButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px 0;
    @include media(mdx) {
      display: inline-flex;
      max-width: 200px;
      margin-right: 20px;
    }
  }

  .pill {
    color: #000000;
    font-size: 24px;
    height: 50px;
    line-height: 1;
    max-width: 50px;
    outline: none;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
  }

  .pillLeft {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .pillRight {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $mint-pink;
    border-top: 2px solid $mint-pink;
    border-radius: 0;
    color: $mint-pink;
    font-family: eurostile-extended, sans-serif;
    font-size: 24px;
    font-weight: 900;
    height: 50px;
    text-align: center;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
    margin: 0;
  }

  .disabledInput {
    border-color: rgba($color: $mint-pink, $alpha: 0.3);
  }

  .etherCost {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    line-height: 1;
    position: absolute;
    top: -60px;
    right: 20px;
    @include media(mdx) {
      align-self: center;
      display: inline-flex;
      margin-left: 30px;
      position: initial;
      vertical-align: middle;
    }
    img {
      position: absolute;
    }
    p {
      color: #f4df0c;
      font-size: 22px;
      font-weight: 900;
      max-width: 70px;
      text-align: center;
      transform: rotate(6.25deg);
    }
  }
}

.noClub {
  background-color: #d80707;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  margin: 20px 10px;
  padding: 20px;
  @include media(mdx) {
    max-width: 430px;
  }

  p {
    font-size: 14px;
    font-weight: 900;
  }
}

.packContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  @include media(mdx) {
    flex-flow: row wrap;
  }
}

.packContainer::after {
  @include media(mdx) {
    content: "";
    flex: auto;
  }
}

.cardContainer {
  justify-content: center;
  @include media(mdx) {
    justify-content: initial;
  }
}

.yourPacks,
.yourCards {
  padding-bottom: 25px;
  h1 {
    padding: 0;
  }
}

.soldOut {
  background-color: rgba($color: $mint-pink, $alpha: 0.3);
  border-radius: 30px;
  color: #0c0b0b;
  margin: 30px auto 50px;
  max-width: 400px;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;

  @include media(mdx) {
    margin: 30px 0 50px;
  }

  p {
    font-size: 16px;
    font-weight: 900;
  }
}
