@import "styles/mixins";

.pack {
    border-radius: 10px;
    border: 1px solid $mint-pink;
    display: flex;
    flex-direction: column;
    width: 47%;
    @include media(mdx) {
        width: 400px;
        height: 600px;
    }
}

.imageContainer {
    height: 220px;
    width: 100%;
    img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 100%;
        width: 100%;
    }
    @include media(mdx) {
        height: 470px;
    }
}

.caption {
    border-top: 1px solid $mint-pink;
    padding: 15px;
    h4 {
        font-size: 16px;
        text-transform: uppercase;
        @include media(mdx) {
            font-size: 24px;
        }
    }
    button {
        background-color: $mint-pink;
        font-size: 14px;
        padding: 10px;
        width: 100%;
    }
    button:disabled {
        background-color: rgba($color: $mint-pink, $alpha: 0.3);
        border: none;
        color: #0c0b0b;
    }
}
