@import "styles/mixins";

.marqueeContainer {
    background: #d22031;
    border: none;
    color: #ffd100;
    height: $marquee-height-mobile;
    overflow: hidden;
    position: relative;

    @include media(mdx) {
      height: $marquee-height-desktop;
    }
}

.marquee {
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    span {
        animation: marquee 60s linear infinite;
        display: inline-block;
        font-size: 22px;
        font-weight: 900;
        letter-spacing: -1.8px;
        padding-left: 100%;
        @include media(mdx) {
            font-size: 36px;
        }
    }
}

.initialMarquee {
    span {
        animation-duration: 30s;
        padding-left: 0;
    }
}

.marquee2 {
    span {
        animation-delay: 30s;
    }
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}