@import "styles/mixins";

.card {
    border-radius: 10px;
    border: 1px solid $mint-pink;
    display: flex;
    flex-direction: column;
    width: 350px;
    @include media(mdx) {
        width: 508px;
    }
}

.imageContainer {
    width: 100%;
    img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 100%;
        width: 100%;
    }
}

.caption {
    border-top: 1px solid $mint-pink;
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-transform: uppercase;
    span {
        color: rgba($color: #FFFFFF, $alpha: 0.7);
        font-size: 14px;
    }
    h4 {
        font-size: 16px;
        @include media(mdx) {
            font-size: 24px;
        }
    }
    a {
        background-color: $mint-pink;
        line-height: 17px;
        padding: 10px;
        text-align: center;
        @include media(mdx) {
            padding: 15px 40px;
        }
    }
}
