@import "styles/mixins";

$text-color: #6635ae;

.claimCrocs {
  background-color: $menu-yellow;
}

.claimCorsContent {
  position: relative;
  padding: 0 10px 30px;
  overflow: hidden;

  @include media(mdx) {
    padding: 0 20px 30px;
  }

  h1 {
    color: $text-color;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 10px 0;

    @include media(mdx) {
      font-size: 24px;
    }
  }

  .description {
    color: $text-color;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.125em;

    @include media(mdx) {
      max-width: 700px;
      margin: 30px 0 50px;
    }
  }

  form {
    @include media(mdx) {
      max-width: 600px;
    }

    label {
      display: block;
      color: $text-color;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 18px;
      margin: 30px 0 10px;
    }

    input,
    select {
      display: block;
      width: 100%;
      background: $menu-yellow;
      color: $text-color;
      text-transform: uppercase;
      font-family: inherit;
      font-weight: 900;
      font-size: 16px;
      border: 2px solid #000000;
      border-radius: 10px;
      margin: 10px 0;
      padding: 10px 20px;

      &::placeholder {
        color: $text-color;
        opacity: 0.6;
      }

      &.error {
        border-color: #d80707;
      }
    }

    input:-webkit-autofill {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: $text-color;
    }

    select {
      appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' focusable='false' role='presentation' style='width: 0.8em; height: 0.8em;'><path d='M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z' fill='rgb(102, 53, 174)' fill-rule='evenodd'></path></svg>");
      background-size: 0.8em 0.8em;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 15px);
      background-position-y: 50%;
    }

    button {
      width: 100%;
      background: #69c333;
      color: $menu-yellow;
      margin-top: 30px;

      @include media(mdx) {
        width: auto;
      }
    }
  }
}

.claimCrocsMobile {
  width: 70%;
  height: auto;

  @include media(mdx) {
    display: none;
  }
}

.claimCrocsDesktop {
  display: none;
  width: 100%;
  max-width: 1000px;
  height: auto;

  @include media(mdx) {
    display: block;
  }
}

.crocsImage {
  position: relative;
  width: 150%;
  height: auto;
  left: 50%;
  transform: translateX(-42%);
  margin: -15% 0 -10%;

  @include media(mdx) {
    height: 65%;
    left: 720px;
    margin: 0;
    position: absolute;
    top: 10%;
    transform: translateX(0);
    width: auto;
  }
}

.cityState {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -5px 0;

  input {
    width: 100%;
    margin: 5px 0 !important;

    @include media(mdx) {
      &:nth-child(1) {
        width: calc(70% - 5px);
      }
      &:nth-child(2) {
        width: calc(30% - 5px);
      }
    }
  }
}

.zipcodeCountry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0;

  input {
    width: 100%;
    margin: 5px 0 !important;

    @include media(mdx) {
      &:nth-child(1) {
        width: calc(30% - 5px);
      }
      &:nth-child(2) {
        width: calc(70% - 5px);
      }
    }
  }
}

.message {
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  padding: 20px 25px;
  border-radius: 10px;
  margin-top: 20px;

  @include media(mdx) {
    max-width: 330px;
  }

  img {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }

  &.error {
    background: #d80707;
  }

  &.success {
    background: #69c333;
  }
}
