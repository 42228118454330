@import "styles/mixins";

.card {
    border-radius: 10px;
    border: 1px solid $mint-pink;
    display: flex;
    flex-direction: column;
    width: 45%;
    @include media(mdx) {
        width: 19%;
    }
}

.imageContainer {
    width: 100%;
    img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 100%;
        width: 100%;
    }
}

.caption {
    border-top: 1px solid $mint-pink;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    text-transform: uppercase;
    span {
        color: rgba(102, 53, 174, 0.7);
        font-size: 14px;
        font-weight: 900;
    }
    h4 {
        font-size: 16px;
        color: #6635AE;
        overflow-wrap: break-word;
        @include media(mdx) {
            font-size: 24px;
        }
    }
}
