.btn {
  border-radius: 50px;
  color: #000000;
  font-family: eurostile-extended, sans-serif;
  font-size: 16px;
  font-weight: 900;
  outline: none;
  padding: 10px 40px;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid #000000;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
}

.btn-primary {
  background-color: $primary;
  color: $button-blue;
}
