$marquee-height-mobile: 36px;
$marquee-height-desktop: 50px;
$primary: #ffd100;
$button-blue: #6734af;
$bg-blue: #30b4e5;
$social-header-height: 98px;
$mint-pink: #f17497;
$mint-black: #1e1e1e;
$menu-yellow: #f4df0c;
$desktop-headers-height: 148px;
