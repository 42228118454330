@import "styles/mixins";

.coming {
  background-color: $bg-blue;
}

.comingSoon {
  background-color: $bg-blue;
  min-height: calc(100vh - #{$social-header-height});
  padding: 0 20px;
  position: relative;

  @include media(mdx) {
    min-height: calc(100vh - #{$desktop-headers-height});
  }
}

.landingMobile {
  width: 100%;
  height: auto;
  margin-top: -20%;

  @include media(md) {
    margin-top: -15%;
  }

  @include media(mdx) {
    display: none;
  }
}

.landingDesktop {
  display: none;
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  max-width: 1000px;

  @include media(mdx) {
    display: block;
    width: auto;
    height: 10vh;
  }
}

.description {
  font-size: 20px;
  font-weight: 500;
  color: $primary;
  margin-bottom: 30px;

  @include media(mdx) {
    font-size: 2.3vh;
    max-width: 500px;
    margin: 30px 0;
  }

  @include media(lg) {
    font-size: 2.5vh;
    max-width: 600px;
    margin: 30px 0;
  }

  @include media(2k) {
    max-width: 1000px;
  }
}

.area54Mobile {
  width: calc(100% + 40px);
  height: auto;
  margin: -15% -20px 0;

  @include media(md) {
    margin-top: -10%;
  }

  @include media(mdx) {
    display: none;
  }
}

.area54Desktop {
  display: none;
  width: auto;
  height: calc(74vh - #{$desktop-headers-height});
  max-width: 100%;

  @include media(mdx) {
    display: block;
    height: calc(70vh - #{$desktop-headers-height});
    margin: -23vh auto 0;
  }

  @include media(lg) {
    display: block;
    height: calc(74vh - #{$desktop-headers-height});
    margin: -28vh auto 0;
  }

  @include media(2k) {
    height: calc(84vh - #{$desktop-headers-height});
  }
}

.footer {
  overflow: hidden;
  margin: 0 -20px;

  img {
    object-fit: contain;
    width: auto;
  }

  @include media(mdx) {
    margin: 20px 0 0;
    img {
      width: 100%;
      height: 50px;
    }
  }
}
