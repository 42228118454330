@import "styles/mixins";

.socialHeader {
  align-items: center;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: sticky;
  transition: top 2s ease-in-out 0s;
  width: 100%;
  z-index: 9;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(20px);
    mask: linear-gradient(black, transparent);
    z-index: -1;
  }

  .orion {
    width: auto;
    height: 42px;

    @include media(mdx) {
      height: 58px;
    }
  }

  .socials {
    display: flex;

    svg {
      width: 30px;
      height: 30px;
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: #ffffff;
    }

    a:first-of-type {
      margin-right: 30px;
    }
  }
}

.fixed {
  top: 0;
}

.menu {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  z-index: 9;
  color: $menu-yellow;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(20px);
    mask: linear-gradient(black, transparent);
    z-index: -1;
  }

  .orion {
    width: auto;
    height: 42px;

    @include media(mdx) {
      height: 58px;
    }
  }

  .content {
    align-self: flex-end;
    width: 100%;
    display: none;
  }

  :global(.btn) {
    padding: 10px 20px;
    background: $menu-yellow;
  }

  .menuBackground {
    width: calc(100% + 40px);
    height: auto;
    margin-left: -20px;
    margin-bottom: 30px;
  }

  .mint,
  .connect {
    display: block;
    margin: 10px 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }

  .socials {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;

    a {
      width: 60px;
      height: 60px;
      background: $bg-blue;
      color: #ffffff;
      border: 2px solid #000000;
      border-radius: 50%;
      padding: 10px;
    }
  }

  &.open {
    position: fixed;
    top: 0;
    height: 100vh;
    height: var(--app-height);
    align-items: flex-start;
    background-color: $menu-yellow;
    background-image: url("../../assets/menu.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 40px;
    color: $bg-blue;

    &:after {
      display: none;
    }

    :global(.btn) {
      background: $bg-blue;
      color: $menu-yellow;
    }

    .content {
      display: block;
    }
  }

  .disconnect {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $button-blue;
    border: 2px solid #000000;
    border-radius: 20px 20px 0px 0px;
    padding: 40px 20px;

    p {
      color: $menu-yellow;
      text-align: center;
      font-size: 20px;
      font-weight: 900;
      line-height: 22px;
      margin-bottom: 20px;
    }

    button {
      display: block;
      width: 100%;
      margin-top: 10px;
    }

    :global(.btn-primary) {
      background: $menu-yellow;
      border-color: #000000;
      color: $button-blue;
    }

    :global(.btn-link) {
      background: transparent;
      border-color: $menu-yellow;
      color: $menu-yellow;
    }

    &.show {
      display: block;
    }

    @include media(mdx) {
      top: 100px;
      right: 20px;
      left: initial;
      bottom: initial;
      width: 300px;
      padding: 20px 10px;
      border-radius: 20px;

      p {
        font-size: 16px;
        line-height: 16px;
        margin: 0;
      }
    }
  }

  @include media(mdx) {
    .toggle {
      display: none;
    }

    .content {
      align-items: center;
      align-self: initial;
      display: flex;
      flex-shrink: 0;
      gap: 15px;
      width: auto;
    }

    .socials {
      order: -1;
      margin: 0;

      a {
        border: none;
        width: 30px;
        height: 30px;
        padding: 0;
        background: none;

        &:first-of-type {
          order: 2;
          margin-left: 20px;
        }
      }
    }

    .mint,
    .connect {
      flex-shrink: 0;
      max-width: 270px;
      width: auto;
    }
  }
}
