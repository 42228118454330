@import "styles/mixins";

.getNotified {
  button {
    position: relative;
    z-index: 2;
  }

  .dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 9;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.2) 0px 6px 20px;
      padding: 16px;
      width: 100%;
      height: 250px;
      max-width: 343px;

      @include media(mdx) {
        max-width: 420px;
      }
    }
  }
}
