@import "styles/mixins";

.landing {
  background-color: $bg-blue;
}

.footer {
  margin: 20px -20px;
  width: calc(100% + 40px);
  height: 50px;
  position: relative;
  z-index: 1;

  @include media(mdx) {
    width: 100%;
    margin: 20px 0;
  }
}

.description {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.125em;
  margin: 30px 0;
  position: relative;
  z-index: 1;

  @include media(mdx) {
    font-size: 26px;
    max-width: 800px;
  }
}

.landingSoon {
  background: $bg-blue;
  color: #f4df0c;
  padding: 0 20px;
  position: relative;

  @include media(mdx) {
    min-height: calc(100vh - #{$desktop-headers-height});
  }

  .landingMobile {
    width: 100%;
    height: auto;
    margin-top: -20%;

    @include media(md) {
      margin-top: -15%;
    }

    @include media(mdx) {
      display: none;
    }
  }

  .landingDesktop {
    display: none;
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    max-width: 1000px;

    @include media(mdx) {
      display: block;
      width: auto;
      height: 10vh;
    }
  }

  .description {
    font-size: 20px;
    font-weight: 500;
    color: $primary;
    margin: 20px 0 30px;

    @include media(mdx) {
      font-size: 2.3vh;
      max-width: 500px;
      margin: 30px 0;
    }

    @include media(lg) {
      font-size: 2.5vh;
      max-width: 600px;
      margin: 30px 0;
    }

    @include media(2k) {
      max-width: 1000px;
    }
  }

  .area54Mobile {
    width: calc(100% + 40px);
    height: auto;
    margin: -15% -20px 0;
    position: relative;
    z-index: 1;

    @include media(md) {
      margin-top: -10%;
    }

    @include media(mdx) {
      display: none;
    }
  }

  .area54Desktop {
    display: none;
    width: auto;
    height: calc(70vh - #{$desktop-headers-height});
    max-width: calc(100% - 40px);
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    margin: -23vh auto 0;

    @include media(mdx) {
      display: block;
    }

    @include media(lg) {
      height: calc(74vh - #{$desktop-headers-height});
      margin: -28vh auto 0;
    }
  }

  :global(.btn) {
    position: relative;
    z-index: 2;
  }

  .price {
    display: none;
    position: relative;
    width: 118px;
    height: 118px;
    background-image: url("../../assets/star-with-stroke.svg");
    background-size: contain;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    line-height: 20px;
    padding: 40px 0;
    margin-top: -80px;
    margin-left: 230px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-21.85deg);
    }

    @include media(mdx) {
      display: block;
    }
  }

  .footer {
    @include media(mdx) {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: calc(100% - 40px);
      margin-bottom: 0;
    }
  }
}

.mintYourPack {
  $color: #ff9e18;

  position: relative;
  background: linear-gradient(
    160.56deg,
    #30b4e5 16.29%,
    #692eb4 33.22%,
    #3b9763 51.32%,
    #d91963 73.26%,
    #ffa8d8 90.2%
  );
  color: $color;
  padding: 40px 20px 0;
  overflow: hidden;

  .mintMobile {
    width: 60%;
    height: auto;

    @include media(mdx) {
      display: none;
    }
  }

  .mintDesktop {
    display: none;
    width: 100%;
    height: auto;

    @include media(mdx) {
      display: block;
    }
  }

  :global(.btn) {
    background: $color;
    width: 100%;
    color: #000000;

    @include media(mdx) {
      width: auto;
    }
  }

  .description {
    margin: 20px 0;

    @include media(mdx) {
      margin: 50px 0 30px;
      max-width: 450px;
    }
  }

  .timeline {
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    border-top: 2px solid $color;
    padding: 10px 0;
    margin-top: 30px;

    @include media(mdx) {
      width: 620px;
    }
  }

  .date {
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid $color;
    padding: 20px 0;
    position: relative;

    span {
      width: 100%;
      font-size: 32px;
      font-weight: 900;
      line-height: 38px;
      -webkit-text-stroke: 2px black;
      position: relative;
      z-index: 1;
    }

    p {
      width: 100%;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.125em;
      margin-top: 10px;

      a {
        color: $mint-black;
        text-decoration: none;
      }
    }

    @include media(mdx) {
      width: 620px;
      justify-content: space-between;

      span {
        width: auto;
      }
    }
  }

  .pack {
    position: relative;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    height: auto;
    margin-bottom: -60%;

    @include media(mdx) {
      position: absolute;
      left: 800px;
      bottom: -200px;
      max-width: 900px;
      margin-bottom: 0;
      transform: translateX(0);
      z-index: 2;
    }
  }

  .price1,
  .price2 {
    display: none;
    width: 100px;
    height: 100px;
    background-image: url("../../assets/star-with-stroke.svg");
    background-size: contain;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 0;

    @include media(mdx) {
      display: block;
      top: -10px;
      right: -100px;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 18px;
      font-weight: 900;
      line-height: 16px;
      -webkit-text-stroke: 0;
      width: auto;
      color: $menu-yellow;
    }
  }

  .price1 span {
    transform: translateX(-50%) translateY(-50%) rotate(-21.85deg);
  }

  .price2 span {
    transform: translateX(-50%) translateY(-50%) rotate(6.25deg);
  }
}

.thePacks {
  $color: #6635ae;
  $background: #f17497;

  position: relative;
  background: $background;
  color: $color;
  padding: 40px 20px 0;
  overflow: hidden;

  .thePacksTitle {
    width: 80%;
    height: auto;
    max-width: 750px;
    position: relative;
    z-index: 3;
  }

  h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 32px;
    -webkit-text-stroke: 2px black;
    margin-top: 10px;

    @include media(mdx) {
      font-size: 48px;
      line-height: 48px;
      max-width: 720px;
      margin-top: 20px;
      position: relative;
      z-index: 3;
    }
  }

  .description {
    position: relative;
    z-index: 3;
    max-width: 700px;
  }

  :global(.btn) {
    color: $background;
    background: $color;
    width: 100%;

    @include media(mdx) {
      width: auto;
      margin-bottom: 100px;
      position: relative;
      z-index: 3;
    }
  }

  .pack {
    position: relative;
    width: 150%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 40px;
    background-image: url("../../assets/the-packs-circle.png");
    background-size: cover;

    img {
      width: 100%;
      height: auto;
    }

    @include media(mdx) {
      width: 800px;
      height: 800px;
      position: absolute;
      top: 50%;
      left: 1000px;
      margin-top: 0;
      transform: translate(0, -50%);
      z-index: 2;

      img {
        position: absolute;
        width: auto;
        height: 140%;
        bottom: -50%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.looksRare {
  $color: #feaebb;
  $background: #69c333;

  position: relative;
  background: $background;
  color: $color;
  padding: 0 20px 0;
  overflow: hidden;

  .looksRareMobile {
    width: calc(100% + 40px);
    height: auto;
    margin: 0 -20px;

    @include media(mdx) {
      display: none;
    }
  }

  .looksRareDesktop {
    display: none;
    width: 100%;
    height: auto;

    @include media(mdx) {
      display: block;
    }
  }

  .nfts {
    display: flex;
    flex-direction: column;

    @include media(mdx) {
      flex-direction: row;
    }
  }

  .nft {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    img {
      flex-shrink: 0;
      height: auto;
      left: 0;
      margin: 20px auto;
      position: relative;
      right: 0;
      width: 100%;
    }

    h1 {
      flex-shrink: 0;
      font-size: 38px;
      font-weight: 900;
      line-height: 38px;
      -webkit-text-stroke: 2px black;
      margin: 10px 0;

      @include media(mdx) {
        font-size: 24px;
      }

      @include media(xl) {
        font-size: 28px;
      }

      @include media(xxl) {
        font-size: 38px;
      }
    }

    p {
      flex-shrink: 0;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
    }

    @include media(mdx) {
      flex-shrink: 0;
      width: calc(25% - 50px);
      margin: 0 25px;

      img {
        order: 2;
        z-index: 2;
      }
    }
  }
}

.fromRonEnglish {
  $color: #ff9e18;
  $background: #6635ae;

  position: relative;
  background: $background;
  color: $color;
  padding: 40px 20px 0;
  overflow: hidden;

  .fromRonMobile {
    width: 100%;
    height: auto;

    @include media(mdx) {
      display: none;
    }
  }

  .fromRonDesktop {
    display: none;
    width: 100%;
    height: auto;
    max-width: 800px;
    position: relative;
    z-index: 2;

    @include media(mdx) {
      display: block;
    }
  }

  .ronEnglishContainer {
    width: 330px;
    position: relative;
    text-align: center;
    margin: 0 auto;

    @include media(mdx) {
      width: auto;
      position: absolute;
      top: 20px;
      left: 760px;
    }
  }

  .ronEnglish {
    width: 330px;
    height: 330px;
    border: 4px solid #000000;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px auto;

    @include media(mdx) {
      margin-right: 225px;
    }
  }

  .aliens {
    width: 200px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: -50px;

    @include media(mdx) {
      width: 300px;
      right: 0;
      bottom: 50%;
      transform: translateY(50%);
    }
  }

  .info {
    @include media(mdx) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin: 30px 0;

      .infoColumn {
        width: 30%;
      }
    }
  }

  .description {
    @include media(mdx) {
      font-size: 22px;
      max-width: initial;
    }
  }

  a {
    display: block;
    text-align: center;
    color: $background;
    background: $color;
    width: 100%;
    margin-bottom: 30px;

    @include media(mdx) {
      width: auto;
      max-width: 380px;
    }
  }
}

.faq {
  $color: #ffd100;
  $background: #30b4e5;

  position: relative;
  background: $background;
  color: $color;
  padding: 20px 20px 0;
  overflow: hidden;

  @include media(mdx) {
    .footer {
      margin-top: 40px;
    }
  }

  .faqTitleMobile {
    width: calc(100% + 20px);
    height: auto;
    margin-bottom: 15px;

    @include media(mdx) {
      display: none;
    }
  }

  .faqTitleDesktop {
    display: none;
    width: 100%;
    height: auto;
    max-width: 900px;
    margin: 20px auto 30px;

    @include media(mdx) {
      display: block;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid $color;
    padding: 20px 0;

    h1 {
      width: calc(100% - 40px);
      font-size: 26px;
      font-weight: 900;
      line-height: 26px;
      -webkit-text-stroke: 1px black;
      text-transform: uppercase;
    }

    button {
      width: 40px;
      height: 40px;
      font-size: 22px;
      font-weight: 900;
      line-height: 22px;
      background: $color;
      border: 2px solid #000000;
      border-radius: 50%;
    }

    p {
      color: #ffffff;
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      max-height: 0;
      overflow: hidden;
      padding-top: 0;
      transition: all 0.2s ease-in-out;
      width: 100%;

      a {
        color: $button-blue;
        text-decoration: none;
      }
    }

    &.active {
      p {
        padding-top: 15px;
        max-height: 1000px;
      }
    }

    @include media(mdx) {
      width: 30%;
      border-top: none;

      h1 {
        width: 100%;
        font-size: 28px;
        line-height: 28px;
      }

      button {
        display: none;
      }

      p {
        max-height: initial;
        padding-top: 15px;
      }
    }
  }
}
