@import "styles/mixins";

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  overflow: hidden;
  padding: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, #000000 100%);
  backdrop-filter: blur(5px);
  text-align: center;

  p {
    color: $menu-yellow;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    margin: 20px 0;

    @include media(mdx) {
      position: absolute;
      left: 40px;
      bottom: 40px;
      margin: 0;
      text-align: left;
    }
  }

  .openingPackMobile {
    width: 100%;
    height: auto;
    margin-top: 100px;

    @include media(mdx) {
      display: none;
    }
  }

  .openingPackDesktop {
    display: none;
    width: 100%;
    max-width: 600px;
    height: auto;
    position: absolute;
    bottom: 100px;
    left: 40px;

    @include media(mdx) {
      display: block;
    }
  }

  .pack {
    width: 150%;
    height: auto;
    margin-left: 50%;
    transform: translateX(-50%);

    @include media(mdx) {
      width: auto;
      height: 100%;
      margin: 0;
      position: absolute;
      left: 0;
      bottom: -20%;
      transform: translateX(700px);
    }
  }
}
