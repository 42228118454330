@import "styles/mixins";

.secret {
  background-color: $menu-yellow;
  min-height: 100vh;
  .headerSection {
    padding: 0 15px;
    @include media(mdx) {
      text-align: center;
    }
  }
  .secretMobile {
    height: auto;
    position: relative;
    width: 100%;
    z-index: 1;

    @include media(mdx) {
      display: none;
    }
  }

  .secretDesktop {
    display: none;
    margin: auto;
    max-width: calc(100% - 40px);
    width: auto;
    @include media(mdx) {
      display: block;
    }
  }

  .headerText {
    color: #6635ae;
    font-size: 24px;
    font-weight: 500;
    margin: 15px 0 35px;
    @include media(md) {
      margin: 15px auto 35px;
      max-width: 500px;
    }
  }

  .headerButton {
    background-color: #69c333;
    border-color: #000000;
    color: $menu-yellow;
  }

  .vipCardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    margin: 40px auto 0;
    padding-bottom: 40px;
    width: 95%;
    @include media(md) {
      gap: 20px;
    }
  }

  .vipCardContainer::after {
    @include media(md) {
      content: "";
      flex: auto;
    }
  }
}
